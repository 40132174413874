import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import LinkButton from '../components/LinkButton';
import Layout from '../components/Layout';
import Section from '../components/Section';
import BlockPage from './BlockPage';
import NewsletterSignupForm from '../components/NewsletterSignupForm';

const ProductPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.productPage;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <BlockPage
          title={page[pageContext.locale].title || page[defaultLocale].title}
          heroImage={page[defaultLocale].image}
          heroContent={
            page[pageContext.locale].hero || page[defaultLocale].hero
          }
          blocks={page[pageContext.locale].blocks || page[defaultLocale].blocks}
        />
        <Section colorScheme="white" overlayLength="large">
          <div className="container md:flex pt-24 pb-12">
            <div className="pb-12 md:w-1/3 md:pr-12">
              <h2>
                <span className="bg-theme-underline">Get In Touch</span>
              </h2>
            </div>
            <div className="pb-12 md:w-2/3 md:pl-12 text-base">
              <p className="pb-12 text-lg">
                Get in touch to find out more about any of our products or to
                discuss how GDI can provide customised data and intelligence
                solutions to help you reduce the risk posed by online
                disinformation.
              </p>
              <LinkButton text="Contact Us" to="/contact" />
            </div>
          </div>
        </Section>
        <Section>
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

ProductPageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPageTemplate;

export const pageQuery = graphql`
  query ProductPage($id: String!) {
    productPage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          image {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
          title
          hero
          blocks {
            colorScheme
            overlayLength
            overlaySide
            left
            right
          }
        }
        de {
          title
          hero
          blocks {
            colorScheme
            overlayLength
            overlaySide
            left
            right
          }
        }
      }
    }
  }
`;
